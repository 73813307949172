
import firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/analytics';
import "firebase/auth";

const config = require("./app_config_staging.json")

// Firebase

const configX = {
    apiKey: config.firebase.apiKey,
    authDomain: config.firebase.authDomain,
    databaseURL: config.firebase.databaseURL,
    projectId: config.firebase.projectId,
    storageBucket: config.firebase.storageBucket,
    messagingSenderId: config.firebase.messagingSenderId,
    appId: config.firebase.appId,
    measurementId: config.measurementId
};

export const firebase_app = !firebase.apps.length ? firebase.initializeApp(configX) : firebase.app();
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const facebookProvider = new firebase.auth.FacebookAuthProvider();
export const twitterProvider = new firebase.auth.TwitterAuthProvider();
export const githubProvider = new firebase.auth.GithubAuthProvider();
export const db = firebase.firestore();
// Jwt
export const Jwt_token = config.jwt_token

export default firebase.firestore();
